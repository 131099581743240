// set column labels based on user provided array
function setLabels(dataTable, labels) {
	for (var i = 0; i < labels.length; i++) {
		dataTable.setColumnLabel(i, labels[i]);
	}
}

// process null value
function processNull(data) {
	if (data == null) {
		console.log('null')
		return null;
	} else if (data == '') {
		console.log('null')
		return null;
	} else {
		console.log('not null')
		return +data;
	}
}

/**
* [function used to set singleAxisComboChart options and test if options are defined]
* @param  {[type]}  option       [description]
* @param  {[type]}  defaultValue [description]
* @return {value}              [description]
*
*
*/
function isDefined(option, defaultValue) {
	if (typeof option === "undefined") {
		return defaultValue;
	} else {
		return option;
	}
}

// create array from Google DataTable
// useful for things like calculating the absolute max/min present in a dataset
function resultsToArray(dateFieldIndex, dataFieldIndexes, data) {
	var resultsArray = [];
	data.forEach(function (v, k) {
		var d = v.c;
		d.forEach(function (vv, kk) {
			if (kk !== dateFieldIndex && dataFieldIndexes.includes(kk)) {
				resultsArray.push(parseFloat(vv.v));
			}
		})
	})
	return resultsArray;
}

// calculate max value in an arry
function arrayMax(arr) {
	return arr.reduce(function (a, b) {
		return Math.max(a, b);
	});
}

// calculate max value in an arry
function arrayMin(arr) {
	return arr.reduce(function (a, b) {
		return Math.min(a, b);
	});
}

// default graph color scheme
var defaultTheme = {
	"primaryColor": "#2196F3",
	"secondaryColor": "#5cb85c",
	"accentColor": "#FFFF00",
	"textColor": "#222",
	"primaryBackgroundColor": "#FFF",
	"secondaryBackgroundColor": "#F2F2F2",
	"seriesColors": ["#cc0000", "#0000ff", "#ffbb33"]
}

// dynamically adjust height of HTML element to match another
function adjustHeight(el1, el2) {
	var $el1 = document.querySelector(el1),
		$el2 = document.querySelector(el2),
		el1Height = $el1.scrollHeight,
		el2Height = $el2.scrollHeight;

	if (el1Height > el2Height) {
		$el2.style.height = el1Height + 'px';
	} else {
		$el1.style.height = el2Height + 'px';
	}
}

// function used to lighten a hex color
function shadeColor(color, percent) {
	var f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
	return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}

/**
 * [function evaluates data, returning a unique list of values present in the data for each key]
 * @param  {[array]} data [an array to be evaluated]
 * @return {[array]}      [returns a unique list of values present in the data for each key]
 */
function unique(data) {
	var series = [];
	var keys = Object.keys(data[0]);
	for (var i = 0; i < keys.length; i++) {
		series.push({ "name": keys[i], "values": [] })
	}
	for (var i = 0; i < series.length; i++) {
		for (var j = 0; j < data.length; j++) {
			if (series[i].values.indexOf(data[j][series[i].name]) == -1) {
				series[i].values.push(data[j][series[i].name]);
			}
		}
	}
	return series
}

/**
 * [return a unique list of years present in the data]
 * @param  {[string]} dateField [the name of the date field in the data parameter]
 * @param  {[array]} data      [an array to be evaluated]
 * @return {[type]}           [description]
 */
function uniqueYears(dateField, data) {
	var years = [];
	for (var i = 0; i < data.length; i++) {
		data[i].year = returnYear(data[i][dateField]);
		if (years.indexOf(data[i].year) == -1) {
			years.push(data[i].year);
		}
	}
	return years;
}

// returns a unique list of months present in the data
function uniqueMonths(dateField, data) {
	var months = [];
	for (var i = 0; i < data.length; i++) {
		data[i].month = returnMonth(data[i][dateField]);
		if (months.indexOf(data[i].month) == -1) {
			months.push(data[i].month);
		}
	}
	return months;
}

// returns a unique list month/year combinations
// for use with a D3JS multi-series column chart
// months represent the x-axis categories while
// years represent the graph sereies
// currently calculates a median value for each month/year
function uniqueMonthsYears(dateField, resultField, data) {
	var months = [], // x-axis categories
		years = [], // series
		uniqueSeries = []; // array to house final output

	// create array of months present in the data
	data.forEach(function (d, i) {
		data[i].month = returnMonth(d[dateField])
		if (months.indexOf(d.month) == -1) {
			months.push(d.month);
		}
	})

	// correctly order months
	sortMonth(months);

	// push each month to uniqueSeries Array
	months.forEach(function (d, i) {
		uniqueSeries.push({ "month": d })
	})

	// create array for years present in the data
	data.forEach(function (d, i) {
		data[i].year = returnYear(d[dateField])
		if (years.indexOf(d.year) == -1) {
			years.push(d.year)
		}
	})


	var keys = years.slice(0); // create keys, in this case the years present in the data
	uniqueSeries["columns"] = keys; // tack onto unique series object

	// for each month object, create a property for each year
	// setting the median as the value
	uniqueSeries.forEach(function (d, i) {
		var results = [];
		data.forEach(function (dd, ii) {
			if (d.month == dd.month) {
				results.push(+dd[resultField]);
				d[dd.year] = d3.median(results);
			}
		})
	});
	return uniqueSeries;
}

// return a list of years present in the data
// to be used as the keys field in a
// multi-series D3JS column chart
function returnYearKeys(dateField, data) {
	var years = [];
	data.forEach(function (d, i) {
		data[i].year = returnYear(d[dateField])
		if (years.indexOf(d.year) == -1) {
			years.push(d.year)
		}
	});

	var keys = years.slice(0);
	return keys
}

// filter and re-organize data based on year
// returns a re-organized array
function parseByYear(dateField, series, data) {
	var parsedData = [];
	series.forEach(function (d, i) {
		parsedData.push([]);
		data.forEach(function (dd, ii) {
			dd.year = returnYear(dd[dateField]);
			if (dd.year == d) {
				parsedData[i].push(dd)
			}
		})
	})
	return parsedData;
}

// filter and re-organize data based on year
// returns a re-organized array
function parseByMonth(dateField, series, data) {
	var parsedData = [];
	series.forEach(function (d, i) {
		parsedData.push([])
		data.forEach(function (dd, ii) {
			dd.month = returnMonth(dd[dateField]);
			if (dd.month == d) {
				parsedData[i].push(dd)
			}
		})
	})
}

// return all object properties
function returnKeys(object) {
	return Object.keys(object);
}

// convert date to year, returns a string
function returnYear(date) {
	return moment(date).format('YYYY');
}

// convert date to year, returns a string
function returnMonth(date) {
	return moment(date).format('MMM');
}

// filter an array based on a provided field
// returns filtered array
function filterArray(filterField, array) {
	var filteredArray = []
	array.filter(function (d) {
		filteredArray.push(+d[filterField]);
	})
	return filteredArray
}

// calculate and return the geometric mean
function geometricMean(data) {
	var newData = data.reduce(function (a, b) {
		return a * b;
	});
	return Math.pow(newData, 1 / data.length)
};

// sort months by month abbreviations
function sortMonth(months) {
	var monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	months.sort(function (a, b) {
		return monthOrder.indexOf(a) - monthOrder.indexOf(b);
	});
	return months;
}